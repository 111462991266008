import axios from "axios";
import Config from "./Config";
import { NotifyAgentModel } from "./Types";

export const notificationsApi = axios.create({
  baseURL: Config.apiUrl,
  withCredentials: false,
});

notificationsApi.defaults.headers.common["Content-Type"] = "application/json";

export const notifyAgentAsync = async (notify: NotifyAgentModel) => {
  try {
    await notificationsApi.post("Notifications", notify);
  } catch (error) {
    console.error("Error connecting:", error);
    throw error;
  }
};
