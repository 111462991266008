import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Dialog,
  Text,
  Button,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  makeStyles,
  Spinner,
} from "@fluentui/react-components";
import ACSService from "../services/ACSService";
import ACSServiceContext from "../services/ACSServiceContext";
import { Call, CallAgent } from "@azure/communication-calling";
import {
  ControlBar,
  MicrophoneButton,
  ScreenShareButton,
  DevicesButton,
  EndCallButton,
  GridLayout,
  VideoTile,
} from "@azure/communication-react";
import { IContextualMenuProps, Stack } from "@fluentui/react";
import ringingSound from "../assets/wav/teams.mp3";

const useStyles = makeStyles({
  mainContainer: {
    minWidth: "900px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    paddingTop: "40px",
    "@media screen and (max-width: 600px)": {
      minWidth: "320px",
      paddingTop: "20px",
    },
  },
  tile: {
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  description: {
    fontSize: "18px",
    paddingBottom: "10px",
    paddingTop: "10px",
    paddingRight: "20%",
    paddingLeft: "20%",
    "@media screen and (max-width: 600px)": {
      paddingRight: "20px",
      paddingLeft: "20px",
    },
  },
  controlBarContainer: {
    marginBottom: "10px",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    "@media screen and (max-width: 600px)": {
      marginTop: "10px",
    },
  },
  startButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
    "@media screen and (max-width: 600px)": {
      marginTop: "10px",
    },
  },
  videoTileCustomer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    marginRigth: "20px",
    marginLeft: "20px",
  },
  titleTile: {
    paddingBottom: "10px",
    paddingTop: "20px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    color: "#d54a58",
    paddingRight: "20%",
    paddingLeft: "20%",
  },
});

type CallPageProps = {
  permissionStatus: string;
  callAgent: CallAgent;
};

const CallPage: React.FC<CallPageProps> = ({ permissionStatus, callAgent }) => {
  const classes = useStyles();

  const keyframes = `
  @keyframes colorChange {
    0% { color: #cccccc; }
    50% { color: #b4bfbf; }
    100% { color: #cccccc; }
  }
`;

  const animationStyle = {
    animation: "colorChange 2s ease-in-out infinite",
  };

  const controlBarStyles = {
    root: {
      backgroundColor: "#f3f2f1",
      borderRadius: "0.5rem",
      maxWidth: "fit-content",
    },
  };

  const videoTileCustomerStyles = {
    root: {
      display: "flex",
      justifyContent: "center",
      height: "30rem",
      width: "30rem",
      borderRadius: "20px",

      "@media screen and (max-width: 600px)": {
        height: "25rem",
        width: "20rem",
      },
    },
  };

  const customStyles = {
    root: {
      height: "inherit",
      background: "darkred",
      color: "white",
      width: "10rem",
      borderRadius: "0px 10px 10px 0px",
    },
    rootHovered: {
      background: "darkred",
      color: "white",
    },
    flexContainer: { flexFlow: "row" },
  };

  const CALL_END_REASON_AGENT_DISCONNECTED = 0;

  const CALL_DISCONNECTED = "Disconnected";
  const CALL_RINGING = "Ringing";
  const CALL_NONE = "None";
  const CALL_CONNECTING = "Connecting";
  const CALL_CONNECTED = "Connected";
  const MICROPHONE_BUTTON_LABEL = "Mikrofón";
  const SCREEN_SHARE_BUTTON_LABEL = "Zdieľať";
  const DEVICES_BUTTON_LABEL = "Nastavenia";
  const END_CALL_BUTTON_LABEL = "Ukončiť hovor";

  const acsServiceInstance = useContext(ACSServiceContext) as ACSService;
  const [inCall, setInCall] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const [isCallConnecting, setIsCallConnecting] = useState(false);
  const [isCallDisconnected, setIsCallDisconnected] = useState(false);
  const [audioButtonChecked, setAudioButtonChecked] = useState(true);
  const [screenshareButtonChecked, setScreenshareButtonChecked] =
    useState(false);
  const [deviceOptionsMenu, setDeviceOptionsMenu] =
    useState<IContextualMenuProps>();
  const [call, setCall] = useState<Call>();
  const [ringingAudio, setRingingAudio] = useState(new Audio(ringingSound));
  const [showConnectingSpinner, setShowConnectingSpinner] =
    useState<boolean>(false);
  const isCustomerCallEnded = useRef(false);
  const isAgentCallEnded = useRef(false);
  const isNoticationSended = useRef(false);
  const isSupportedOS = useRef(false);
  ringingAudio.loop = true;

  const initCall = async () => {
    const agentId = await acsServiceInstance.getAgentId();
    if (agentId) {
      const isAgentConnected =
        await acsServiceInstance.getConnectedIsAgentConnectedSync(agentId);

      if (!isAgentConnected) {
        setIsCallDisconnected(true);
        setInCall(false);
      }

      if (isAgentConnected) {
        setIsCallDisconnected(false);
        const call = await acsServiceInstance.startCall(callAgent);

        if (call) {
          setCall(call);
          setInCall(true);

          ringingAudio.play();
        }
      }
    }
  };

  useEffect(() => {
    const getSupportedOS = acsServiceInstance.getSupportedOS();
    isSupportedOS.current = getSupportedOS.isSupportedOS;

    acsServiceInstance.getDeviceContextMenu().then((devices) => {
      setDeviceOptionsMenu(devices);
    });

    acsServiceInstance.handleAudioChangeEvent.on(
      "audioChanged",
      (selectedAudio) => {
        acsServiceInstance
          .getDeviceContextMenu(selectedAudio)
          .then((devices) => {
            setDeviceOptionsMenu(devices);
          });
      }
    );

    const intervalInitCall = setInterval(async () => {
      if (!isCustomerCallEnded.current && !isAgentCallEnded.current) {
        const callInfo = await acsServiceInstance.getCallInfo();
        if (callInfo !== undefined) {
          if (
            callInfo.state === CALL_DISCONNECTED ||
            callInfo.state === CALL_CONNECTING ||
            callInfo.state === CALL_NONE
          ) {
            await initCall();
          }
        }
      }
    }, 8500);

    const intervalStateOfCall = setInterval(() => {
      getStateOfCall();
    }, 1000);

    return () => {
      clearInterval(intervalStateOfCall);
      clearInterval(intervalInitCall);
    };
  }, []);

  async function getStateOfCall() {
    const callInfo = await acsServiceInstance.getCallInfo();

    try {
      if (callInfo !== undefined) {
        if (
          callInfo.callEndReason?.code === CALL_END_REASON_AGENT_DISCONNECTED
        ) {
          isAgentCallEnded.current = true;
        }
        if (callInfo.state === CALL_CONNECTING) {
          setIsCallAccepted(false);
          setShowConnectingSpinner(false);
          setIsCallConnecting(true);
        }
        if (callInfo.state === CALL_CONNECTED) {
          ringingAudio.loop = false;
          ringingAudio.pause();
          setIsCallAccepted(true);
          setIsCallConnecting(false);
        }
        if (callInfo !== undefined && callInfo.state === CALL_DISCONNECTED) {
          setInCall(false);
          setIsCallAccepted(false);
        }
      }
    } catch (error) {
      console.error("Error fetching call info:", error);
    }
  }

  const handleMicrophoneButtonClick = async () => {
    setAudioButtonChecked(!audioButtonChecked);
    if (!audioButtonChecked) {
      await acsServiceInstance.mute();
    } else {
      await acsServiceInstance.unMute();
    }
  };

  const handleScreenShareButtonClick = async () => {
    setScreenshareButtonChecked(!screenshareButtonChecked);
    if (!screenshareButtonChecked) {
      await acsServiceInstance.screenShareStart();
    } else {
      await acsServiceInstance.screenShareStop();
    }
  };

  const handleEndCallButtonClick = () => {
    acsServiceInstance.endCall();
    setInCall(false);
    isCustomerCallEnded.current = true;
  };

  async function connectAgent() {
    setShowConnectingSpinner(true);
    await initCall();
    if (!isNoticationSended.current) {
      await acsServiceInstance.notifyAgent();
    }
    isNoticationSended.current = true;
    isCustomerCallEnded.current = false;
    isAgentCallEnded.current = false;
  }

  const commonTexts = (
    <div>
      <Text>
        <h2 className={classes.tile}>
          <strong>Prístup k mikrofónu a zdieľaniu obrazovky</strong>
        </h2>
      </Text>
      <Text align="justify" block className={classes.description}>
        Aby sme mohli zlepšiť vaše skúsenosti s našou aplikáciou a poskytovať
        Vám lepšie služby, potrebujeme prístup k funkciám mikrofónu a zdieľania
        obrazovky. Tieto oprávnenia sú kľúčové pre špecifické funkcie a funkcie,
        ktoré sú navrhnuté tak, aby obohatili Vaše používanie aplikácie.
      </Text>
      <Text align="justify" block className={classes.description}>
        Tento prístup je nevyhnutný pre činnosti ako hlasové hovory, hlasové
        správy a ďalšie funkcie založené na zvuku, ktoré robia aplikáciu
        interaktívnejšou a pre používateľov prívetivejšou.
      </Text>
      <Text align="justify" block className={classes.description}>
        Viac informácií k zásadám spracúvania osobných údajov nájdete na webovom
        sídle spoločnosti TIPOS, v sekcii{" "}
        <a
          href="https://www.tipos.sk/stranky/spolocnost-tipos/pravne-informacie/ochrana-sukromia/chatbot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ochrana súkromia
        </a>
      </Text>
    </div>
  );

  return (
    <div
      className={classes.mainContainer}
      style={{ borderRadius: "20px", overflow: "hidden" }}
    >
      {isCallAccepted ? (
        <div className={classes.videoTileCustomer}>
          <div>
            <Stack horizontal horizontalAlign="space-evenly">
              <GridLayout>
                <VideoTile
                  styles={videoTileCustomerStyles}
                  displayName={"Agent"}
                />
              </GridLayout>
            </Stack>
          </div>
        </div>
      ) : (
        <div>{commonTexts}</div>
      )}

      {permissionStatus === "denied" && (
        <Dialog open={true}>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>
                Povolenie na používanie mikrofónu bolo zamietnuté
              </DialogTitle>
              <DialogContent>
                Neexistuje možnosť prístupu k mikrofónu. Kliknutím na ikonu
                zamknutia v riadku adresy dáte tejto webovej stránke povolenie
                na využitie mikrofónu. Prípadne môže byť nutné obnoviť stránku.
              </DialogContent>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}

      {permissionStatus === "granted" && (
        <div>
          {isCallDisconnected ? (
            <div>
              <style>{keyframes}</style>
              <Text
                align="center"
                size={400}
                block
                className={classes.titleTile}
                style={animationStyle}
              >
                <strong style={{ maxWidth: "680px" }}>
                  Agent je odpojený z hovoru a nie je ho možné mu zavolať,
                  kontaktujte Tipos Live Chat.
                </strong>
              </Text>
            </div>
          ) : isCallConnecting ? (
            <Spinner
              size="huge"
              labelPosition="below"
              label="Prosím čakajte na pripojenie agenta"
            />
          ) : isCallAccepted ? (
            <div className={classes.controlBarContainer}>
              <ControlBar layout={"horizontal"} styles={controlBarStyles}>
                <MicrophoneButton
                  checked={audioButtonChecked}
                  onToggleMicrophone={handleMicrophoneButtonClick}
                  enableDeviceSelectionMenu={false}
                  showLabel={true}
                  onRenderText={() => (
                    <span
                      key={"hangupBtnTextKey"}
                      style={{ marginLeft: "0.2rem" }}
                    >
                      {MICROPHONE_BUTTON_LABEL}
                    </span>
                  )}
                />
                {isSupportedOS.current && (
                  <ScreenShareButton
                    checked={screenshareButtonChecked}
                    onClick={handleScreenShareButtonClick}
                    showLabel={true}
                    onRenderText={() => (
                      <span
                        key={"hangupBtnTextKey"}
                        style={{ marginLeft: "0.2rem" }}
                      >
                        {SCREEN_SHARE_BUTTON_LABEL}
                      </span>
                    )}
                  />
                )}
                {deviceOptionsMenu && (
                  <DevicesButton menuProps={deviceOptionsMenu} showLabel={true}          onRenderText={() => (
                    <span
                      key={"hangupBtnTextKey"}
                      style={{ marginLeft: "0.2rem" }}
                    >
                      {DEVICES_BUTTON_LABEL}
                    </span>
                  )} />
                )}
                <EndCallButton
                  styles={customStyles}
                  showLabel={true}
                  onRenderText={() => (
                    <span
                      key={"hangupBtnTextKey"}
                      style={{ marginLeft: "0.2rem" }}
                    >
                      {END_CALL_BUTTON_LABEL}
                    </span>
                  )}
                  onClick={handleEndCallButtonClick}
                />
              </ControlBar>
            </div>
          ) : showConnectingSpinner ? (
            <Spinner
              size="huge"
              labelPosition="below"
              label="Prosím čakajte na pripojenie agenta"
            />
          ) : isCustomerCallEnded.current ? (
            <div>
              <style>{keyframes}</style>
              <Text
                align="center"
                size={400}
                block
                className={classes.titleTile}
                style={animationStyle}
              >
                <strong style={{ maxWidth: "680px" }}>
                  Váš hovor bol ukončený. Ak potrebujete opäť hovoriť s agentom,
                  kontaktujte ho pomocou Live Chatu.
                </strong>
              </Text>
            </div>
          ) : isAgentCallEnded.current ? (
            <div>
              <style>{keyframes}</style>
              <Text
                align="center"
                size={400}
                block
                className={classes.titleTile}
                style={animationStyle}
              >
                <strong style={{ maxWidth: "680px" }}>
                  Váš hovor bol ukončený agentom. Ak potrebujete opäť hovoriť s
                  agentom, kontaktujte ho pomocou Live Chatu.
                </strong>
              </Text>
            </div>
          ) : (
            <div className={classes.startButtonContainer}>
              <Button
                size="large"
                onClick={() => connectAgent()}
                appearance="primary"
              >
                Začnite komunikáciu s agentom
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CallPage;
