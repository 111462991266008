import React from 'react';
import ACSService from './ACSService';

const ACSServiceContext = React.createContext<ACSService | null>(null);

export const ACSServiceProvider: React.FC = ({ children }) => {
  const serviceInstance = new ACSService();

  return (
    <ACSServiceContext.Provider value={serviceInstance}>
      {children}
    </ACSServiceContext.Provider>
  );
};

export default ACSServiceContext;
