import { AudioDeviceInfo, Call, CallAgent } from "@azure/communication-calling";
import { getCallSession } from "./ConnectionsLinksService";
import {
  StatefulCallClient,
  createStatefulCallClient,
} from "@azure/communication-react";
import { ICallSessionData, NotifyAgentModel } from "./Types";
import { ContextualMenuItemType, IContextualMenuProps } from "@fluentui/react";
import EventEmitter from "events";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { getConnectedIsAgentConnected } from "./AgentConnectionsService";
import { notifyAgentAsync } from "./NotificationsService";

let callSession: ICallSessionData;
let sessionId: string;

let callClient: StatefulCallClient;
let call: Call;

let publicUser: any;
let publicUserToken: any;
let publicUserCredential: any;

class ACSService {
  async initializeDeviceBeforeCall(client: StatefulCallClient): Promise<void> {
    if (client) {
      const deviceManager = await client.getDeviceManager();
      await deviceManager.askDevicePermission({ audio: true, video: false });
    }
  }

  async createCallClient(): Promise<{
    callClient: StatefulCallClient | null;
    callAgent: CallAgent | null;
  } | null> {
    try {
      const sParamValue = this.getQueryParamValue("s");

      if (sParamValue === null) {
        console.log('no "s" parameter in url');
        return null;
      }

      sessionId = sParamValue as string;
      let result = null;

      result = await getCallSession(sessionId);

      if (!result) {
        return null;
      }

      callSession = result;

      publicUser = {
        communicationUserId: callSession.publicUserId,
        displayName: callSession.publicUserName,
      };

      publicUserToken = callSession.publicUserToken;

      publicUserCredential = new AzureCommunicationTokenCredential(
        publicUserToken
      );

      callClient = createStatefulCallClient({
        userId: { communicationUserId: callSession.publicUserId },
      });

      await this.initializeDeviceBeforeCall(callClient);

      const callAgent: CallAgent = await callClient.createCallAgent(
        publicUserCredential,
        {
          displayName: publicUser.displayName,
        }
      );

      return { callClient, callAgent };
    } catch (error) {
      console.log(error);
      return { callClient: null, callAgent: null };
    }
  }

  async getCallInfo(): Promise<Call> {
    return call;
  }

  private getQueryParamValue(paramName: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  }

  async startCall(callAgent: CallAgent): Promise<Call | null> {
    console.log("Start new call with agent " + callSession.agentUserId);

    try {
      const agentUser = { communicationUserId: callSession.agentUserId };
      call = callAgent.startCall([agentUser], {});

      console.log("callid:", call);
      return call;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async endCall() {
    try {
      call.hangUp({ forEveryone: false });
    } catch (err) {
      console.log(err);
    }
  }

  async screenShareStart(): Promise<void> {
    try {
      if (!call.isScreenSharingOn) {
        await call.startScreenSharing();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async screenShareStop(): Promise<void> {
    try {
      if (call.isScreenSharingOn) {
        await call.stopScreenSharing();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getMicrophones(): Promise<AudioDeviceInfo[] | null> {
    try {
      const deviceManager = await callClient.getDeviceManager();
      return await deviceManager.getMicrophones();
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async getSpeakers(): Promise<AudioDeviceInfo[] | null> {
    try {
      const deviceManager = await callClient.getDeviceManager();
      return await deviceManager.getSpeakers();
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  handleAudioChangeEvent = new EventEmitter();

  async getDeviceContextMenu(
    selectedAudio?: AudioDeviceInfo
  ): Promise<IContextualMenuProps> {
    const mics = await this.getMicrophones();
    const speakers = await this.getSpeakers();
    let menu: IContextualMenuProps = {
      items: [],
    };

    if (mics) {
      menu.items.push({
        itemType: ContextualMenuItemType.Header,
        key: "Mic",
        text: "Mikrofón",
      });

      mics.forEach((mic) => {
        menu.items.push({
          checked: this.isAudioSelected(mic, selectedAudio),
          key: mic.id,
          name: mic.name,
          canCheck: true,
          onClick: () => {
            this.changeMicrophone(mic).then(() => {
              console.log("Microphone changed!");
            });
          },
        });
      });
    }

    if (speakers) {
      menu.items.push({
        itemType: ContextualMenuItemType.Header,
        key: "Speaker",
        text: "Reproduktor",
      });

      speakers.forEach((speaker) => {
        menu.items.push({
          checked: this.isAudioSelected(speaker, selectedAudio),
          key: speaker.id,
          name: speaker.name,
          canCheck: true,
          onClick: () => {
            this.changeSpeaker(speaker).then(() => {
              console.log("Speaker changed!");
            });
          },
        });
      });
    }

    return menu as IContextualMenuProps;
  }

  isAudioSelected(
    audio: AudioDeviceInfo,
    selectedAudio?: AudioDeviceInfo
  ): boolean {
    if (!selectedAudio) {
      return audio.isSystemDefault;
    }

    if (audio.deviceType === selectedAudio.deviceType) {
      if (audio.id === selectedAudio.id) {
        return true;
      }
      return false;
    }
    return audio.isSystemDefault;
  }

  async changeMicrophone(mic: AudioDeviceInfo): Promise<void> {
    const deviceManager = await callClient.getDeviceManager();
    await deviceManager.selectMicrophone(mic);
    this.handleAudioChangeEvent.emit("audioChanged", mic);
  }

  async changeSpeaker(speaker: AudioDeviceInfo): Promise<void> {
    const deviceManager = await callClient.getDeviceManager();
    await deviceManager.selectSpeaker(speaker);
    this.handleAudioChangeEvent.emit("audioChanged", speaker);
  }

  async unMute(): Promise<void> {
    try {
      if (!call.isMuted) {
        call.mute();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async mute(): Promise<void> {
    try {
      if (call.isMuted) {
        call.unmute();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getConnectedIsAgentConnectedSync(acsId: string): Promise<boolean> {
    const isConnectedAgent = await getConnectedIsAgentConnected(acsId);
    return isConnectedAgent;
  }

  async getAgentId(): Promise<string> {
    return callSession.agentUserId;
  }

  async notifyAgent() {
    try {
      const notifyAgent: NotifyAgentModel = {
        sessionId: sessionId,
      };

      await notifyAgentAsync(notifyAgent);
    } catch (err) {
      console.error(err);
    }
  }

  validateSupportedBrowser = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const { isSupportedWebBrowser } = this.getBrowserAndOSInformation();
      resolve(isSupportedWebBrowser);
    });
  };

  validateSupportedOS = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const { isSupportedOS } = this.getSupportedOS();
      resolve(isSupportedOS);
    });
  };

  getBrowserNameAndVersion = (): { name: string; version: string } => {
    const ua = navigator.userAgent;
    let tem;
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

    if (/trident/i.test(M[1])) {
      // For Internet Explorer (IE)
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }

    if (M[1] === "Chrome") {
      // For Chromium-based browsers (Chrome, Edge)
      tem = ua.match(/\bEdg\/(\d+)/);
      if (tem != null) {
        return { name: "Edge(Chromium)", version: tem[1] };
      }

      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        // For Opera
        return { name: "Opera", version: tem[1] };
      }
    }

    // For other browsers (Safari, Firefox)
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }

    return {
      name: M[0].toLowerCase(),
      version: M[1],
    };
  };

  getSupportedOS = (): { isSupportedOS: boolean } => {
    const ua = navigator.userAgent;
    const osNotAvailableKeywords = ["Android", "iPhone", "X11"];

    for (const osKeyword of osNotAvailableKeywords) {
      if (ua.includes(osKeyword)) {
        return {
          isSupportedOS: false,
        };
      }
    }
    return {
      isSupportedOS: true,
    };
  };

  getBrowserAndOSInformation = (): {
    isSupportedWebBrowser: boolean;
  } => {
    const { name } = this.getBrowserNameAndVersion();
    const supportedWebBrowsers = [
      "chrome",
      "edge(chromium)",
      "firefox",
      "opera",
      "safari",
    ];
    return {
      isSupportedWebBrowser: supportedWebBrowsers.includes(name.toLowerCase()),
    };
  };
}

export default ACSService;
